/* 文章列表卡片自定义样式 */
.card-custom-hover-style {
  transition-property: transform, box-shadow;
}

.card-custom-hover-style .arco-card-body {
  padding: 10px;
}

.card-custom-hover-style:hover {
  transform: translateY(-4px);
  box-shadow: 0 4px 10px rgb(var(--gray-3));
}

.card-custom-hover-style img {
  transition: scale 0.15s ease-in-out; /* 添加过渡效果 */
}

.card-custom-hover-style:hover img {
  /*opacity: 0.9; !* 设置透明度为 0.90 *!*/
  scale: 1.05;
}

.card-custom-selected-style {
  background-color: var(--color-fill-2);
  border: 1px solid var(--color-border-2);
}
