.fade-enter {
  opacity: 0;
  transform: translateY(20px);
}

.fade-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition:
    opacity 200ms,
    transform 200ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transform: translateY(20px);
  transition:
    opacity 200ms,
    transform 200ms;
}
